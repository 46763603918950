import Web3 from "web3";
import { toast } from "react-toastify";
import { abiUniSwapV2Router } from "../abi/IUniswapV2router";
import { ABI_ERC20 } from "../abi/abi_erc20";
import config from "../data/config";

const tokenAddress = "0xd3210f246ae54c5a45a7b4a83315bf718f591bfc"; // Constant token address

const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

export async function getRewardBalance(
  web3,
  userAddress,
  rewardTokenContractAddress,
) {
  const rewardTokenContract = new web3.eth.Contract(
    ABI_ERC20,
    rewardTokenContractAddress,
  );
  const balance = await rewardTokenContract.methods
    .balanceOf(userAddress)
    .call();
  return balance; // The balance is usually returned in Wei
}

export async function initializeWeb3() {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    try {
      await window.ethereum.enable(); // Request account access if needed
      return web3; // Return the initialized web3 instance
    } catch (error) {
      console.error("User denied account access:", error);
    }
  } else {
    console.error(
      "Non-Ethereum browser detected. You should consider trying MetaMask!",
    );
  }
  return null; // Return null if web3 couldn't be initialized
}

export async function performSwap(web3, amountETH) {
  if (!web3 || !web3.eth) {
    throw new Error("Web3 instance is not initialized.");
  }
  const tokenContract = new web3.eth.Contract(ABI_ERC20, tokenAddress);
  const dexRouter = new web3.eth.Contract(abiUniSwapV2Router, routerAddress);
  console.log(dexRouter.methods);
  const amountInWei = web3.utils.toWei(amountETH.toString(), "ether");
  const userAddress = (await web3.eth.getAccounts())[0];
  const path = [config.WETH_ADDRESS, tokenAddress];
  const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
  const estimatedGas = await dexRouter.methods
    .swapExactETHForTokens(0, path, userAddress, deadline)
    .estimateGas({ from: userAddress, value: amountInWei });

  const tx = {
    from: userAddress,
    to: config.routerAddress,
    data: dexRouter.methods
      .swapExactETHForTokens(
        web3.utils.toWei("0.01", "ether"), // A realistic minimum amount of tokens you are willing to accept
        path,
        userAddress,
        deadline,
      )
      .encodeABI(),
    value: amountInWei,
    gas: estimatedGas,
  };

  const txReceipt = await web3.eth.sendTransaction(tx);
  console.log("Transaction Receipt:", txReceipt);
}

export async function autoCompound(web3, rewardBalance) {
  try {
    await performSwap(web3, rewardBalance);
    toast.success("Rewards auto-compounded successfully");
  } catch (e) {
    toast.error(`Error in auto-compounding: ${e.message}`);
    throw e;
  }
}
