import WalletConnectProvider from "@walletconnect/web3-provider";
import moment from "moment";
import { toast } from "react-toastify";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { MIN_ABI } from "../abi/abi";
import { ABI_ERC20 } from "../abi/abi_erc20";
import { ABI_STAKING } from "../abi/abi_staking_bk";
import { setLoading } from "../actions/authActions";
import config from "../data/config.json";

const SIGN_MESSAGE = `Please sign this message to verify the ownership of your address. This process will not use any ETH. Timestamp: `;
const PROVIDER = new Web3.providers.WebsocketProvider(config.ethNode);
const WEB3 = new Web3(PROVIDER);

const providerOptions = {
  injected: {
    display: {
      name: "Metamask",
      description: "Connect with the provider in your Browser",
    },
    package: null,
  },
  walletconnect: {
    display: {
      name: "WalletConnect",
      description: "Scan a QR code with your mobile wallet",
    },
    package: WalletConnectProvider,
    options: {
      rpc: config.quickNodeHttp,
      bridge: config.walletConnectBridge,
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions,
});

async function connectWallet() {
  try {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);

    const address = (await web3.eth.getAccounts())[0];
    return address;
  } catch (e) {
    disconnectWallet();
  }
  return null;
}

async function requestSignature(address, timestamp) {
  try {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    if (provider._setAddresses) {
      provider._setAddresses([address.toLowerCase()]);
    }

    const signMessage = SIGN_MESSAGE.concat(timestamp);
    const encodedMessage = Buffer.from(signMessage).toString("hex");

    const signature = await web3.eth.personal.sign(signMessage, address);
    return { signature, encodedMessage };
  } catch (e) {
    disconnectWallet();
  }
  return null;
}

async function withdraw(
  simulate,
  dispatch,
  identifier,
  amount,
  nonce,
  signature,
) {
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const address = (await web3.eth.getAccounts())[0];
  try {
    const network = await web3.eth.net.getId();

    if (network !== 1 && !simulate) {
      toast.error(
        "Wrong network selected, please make sure you're connected to the ETH network",
      );
      return;
    }

    const contract = new web3.eth.Contract(
      MIN_ABI,
      config.rewardsTrackerAddress,
    );
    const withdraw = await contract.methods.withdraw(
      identifier,
      amount,
      nonce,
      signature,
    );
    let data = withdraw.encodeABI();

    const gasPrice = await web3.eth.getGasPrice();
    const estimatedGas = await contract.methods
      .withdraw(identifier, amount, nonce, signature)
      .estimateGas({ from: address });

    const tx = {
      from: address,
      to: contract.options.address,
      gas: estimatedGas,
      gasPrice: Web3.utils
        .toBN(gasPrice)
        .mul(Web3.utils.toBN(120))
        .div(Web3.utils.toBN(100))
        .toString(),
      data: data,
    };

    if (simulate) {
      return true;
    }

    await web3.eth.sendTransaction(tx);
    toast.success("Your rewards have been withdrawn");
  } catch (e) {
    if (simulate) {
      return false;
    }
    toast.error(e.message);

    throw e.message;
  }
}

async function hasEnoughTokenBalance(userWallet) {
  try {
    const min = 0;
    const stakingBalance = await getTotalStaked(userWallet);
    const addressContract = new WEB3.eth.Contract(
      ABI_ERC20,
      config.balanceAddress,
    );
    const userBalance = await addressContract.methods
      .balanceOf(userWallet)
      .call();
    const decimals = await addressContract.methods.decimals().call();

    const total = Web3.utils
      .toBN(userBalance)
      .div(Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals)))
      .add(Web3.utils.toBN(stakingBalance));

    return Web3.utils.toBN(total).gte(Web3.utils.toBN(min));
  } catch (e) {
    console.error(e);
  }
  return -1;
}

async function checkIfUserIsStaking(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const deposit = await contract.methods.deposits(userWallet).call();
    return deposit.active;
  } catch (e) {
    console.error(e);
  }
}

async function userBracket(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const deposit = await contract.methods.deposits(userWallet).call();
    if (deposit.active) {
      return deposit.bracket;
    }
    return null;
  } catch (e) {
    console.error(e);
  }
}

async function checkIfCanClaim(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const deposit = await contract.methods.deposits(userWallet).call();
    if (deposit.active) {
      const y = deposit.info.timestamp;
      const lockedDays = deposit.bracket["lockedDays"];
      const f = moment.unix(y).format("YYYY-MM-DD");
      const start = moment();
      const diff = start.diff(f, "days");
      const compare = diff >= lockedDays;
      return compare;
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
  }
}

async function daysUntilClaim(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const deposit = await contract.methods.deposits(userWallet).call();
    if (deposit.active) {
      const lockedDays = deposit.bracket["lockedDays"];
      const format = "YYYY-MM-DD HH:MM";
      const stakeTimestamp = deposit.info.timestamp;

      const today = moment().format(format);
      const unixToDate = moment.unix(stakeTimestamp).format(format);
      const someDay = moment(unixToDate, format)
        .add(lockedDays, "days")
        .format(format);
      const end = moment(someDay);
      const when = end.from(today);

      return when;
    } else {
      return 0;
    }
  } catch (e) {
    console.error(e);
  }
}

async function stakeAmount(dispatch, userWallet, amount, bracketIndex) {
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const stakingContract = new web3.eth.Contract(
    ABI_STAKING,
    config.stakingAddress,
  );
  const depositAddress = await stakingContract.methods.depositToken().call();
  const depositContract = new web3.eth.Contract(ABI_ERC20, depositAddress);
  const allowance = await depositContract.methods
    .allowance(userWallet, config.stakingAddress)
    .call();
  const decimals = await depositContract.methods.decimals().call();

  try {
    const totalAmount = Web3.utils
      .toBN(amount.toString())
      .mul(Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals)));
    const missingAllowance = totalAmount.sub(Web3.utils.toBN(allowance));
    const hasApproved = missingAllowance.lte(Web3.utils.toBN(0));

    if (hasApproved) {
      dispatch(setLoading(true));
      const depositStake = await stakingContract.methods.deposit(
        totalAmount.toString(),
        bracketIndex,
      );
      let dataStake = depositStake.encodeABI();
      const gasPrice = await web3.eth.getGasPrice();
      const estimatedGas = await stakingContract.methods
        .deposit(totalAmount.toString(), bracketIndex)
        .estimateGas({ from: userWallet });
      const tx = {
        from: userWallet,
        to: stakingContract.options.address,
        gas: estimatedGas,
        gasPrice: Web3.utils
          .toBN(gasPrice)
          .mul(Web3.utils.toBN(120))
          .div(Web3.utils.toBN(100))
          .toString(),
        data: dataStake,
      };
      await web3.eth
        .sendTransaction(tx)
        .on("error", (error) => {
          dispatch(setLoading(false));
          toast.error(error.message);
        })
        .then(() => {
          toast.success("Amount Staked");
          window.location.reload();
        });
    } else {
      const approve = await depositContract.methods.approve(
        config.stakingAddress,
        totalAmount.toString(),
      );
      let data = approve.encodeABI();
      const estimatedGas = await depositContract.methods
        .approve(config.stakingAddress, totalAmount.toString())
        .estimateGas({ from: userWallet });
      const gasPrice = await web3.eth.getGasPrice();
      const tx = {
        from: userWallet,
        to: depositContract.options.address,
        gas: estimatedGas,
        gasPrice: Web3.utils
          .toBN(gasPrice)
          .mul(Web3.utils.toBN(120))
          .div(Web3.utils.toBN(100))
          .toString(),
        data: data,
      };
      await web3.eth
        .sendTransaction(tx)
        .once("sending", (sending) => {
          dispatch(setLoading(true));
        })
        .on("error", (error) => {
          dispatch(setLoading(false));
          toast.error(error.message);
        })
        .then(async () => {
          const depositStake = await stakingContract.methods.deposit(
            totalAmount.toString(),
            bracketIndex,
          );
          let dataStake = depositStake.encodeABI();
          const estimatedGas = await stakingContract.methods
            .deposit(totalAmount.toString(), bracketIndex)
            .estimateGas({ from: userWallet });
          const tx = {
            from: userWallet,
            to: stakingContract.options.address,
            gas: estimatedGas,
            gasPrice: Web3.utils
              .toBN(gasPrice)
              .mul(Web3.utils.toBN(120))
              .div(Web3.utils.toBN(100))
              .toString(),
            data: dataStake,
          };
          await web3.eth
            .sendTransaction(tx)
            .on("error", (error) => {
              dispatch(setLoading(false));
              toast.error(error.message);
            })
            .then(() => {
              dispatch(setLoading(false));
              toast.success("Successfully staked");
              window.location.reload();
            });
        });
    }
  } catch (e) {
    console.error(e);
  }
}

async function getBracket(index) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const bracket = await contract.methods.brackets(index).call();
    return bracket;
  } catch (e) {
    console.error(e);
  }
}

async function listBrackets() {
  let brackets = [];
  try {
    for (var i = 0; ; i++) {
      const currentBracket = await getBracket(i);
      if (!currentBracket.enabled) break;
      brackets.push(currentBracket);
    }
  } catch (e) {
    console.error(e);
  }
  return brackets;
}

async function getStakeTokenBalance(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const address = await contract.methods.depositToken().call();
    const addressContract = new WEB3.eth.Contract(ABI_ERC20, address);
    const userBalance = await addressContract.methods
      .balanceOf(userWallet)
      .call();
    const decimals = await addressContract.methods.decimals().call();
    return Web3.utils
      .toBN(userBalance)
      .div(Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals)))
      .toString();
  } catch (e) {
    console.error(e);
  }
  return "0";
}

async function getTotalStaked(userWallet) {
  try {
    const contract = new WEB3.eth.Contract(ABI_STAKING, config.stakingAddress);
    const deposit = await contract.methods.deposits(userWallet).call();
    const address = await contract.methods.depositToken().call();
    const addressContract = new WEB3.eth.Contract(ABI_ERC20, address);
    const decimals = await addressContract.methods.decimals().call();
    if (deposit.active) {
      return Web3.utils
        .toBN(deposit.info.amount)
        .div(Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals)))
        .toString();
    } else {
      return "0";
    }
  } catch (e) {
    console.error(e);
  }
  return "0";
}

async function calculateRewards(userWallet) {
  try {
    const stakingContract = new WEB3.eth.Contract(
      ABI_STAKING,
      config.stakingAddress,
    );
    const userRewards = await stakingContract.methods
      .calculateRewards(userWallet)
      .call();
    const rewardsTokenAddress = await stakingContract.methods
      .rewardsToken()
      .call();
    const rewardsTokenContract = new WEB3.eth.Contract(
      ABI_ERC20,
      rewardsTokenAddress,
    );
    const decimals = await rewardsTokenContract.methods.decimals().call();
    return Web3.utils
      .toBN(userRewards)
      .div(Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals)))
      .toString();
  } catch (e) {
    console.error(e);
  }
}

async function claimStakingRewards(dispatch, userWallet) {
  dispatch(setLoading(true));
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(ABI_STAKING, config.stakingAddress);
  const withdraw = await contract.methods.withdraw();
  let dataStake = withdraw.encodeABI();
  const gasPrice = await web3.eth.getGasPrice();
  const estimatedGas = await contract.methods
    .withdraw()
    .estimateGas({ from: userWallet });
  const tx = {
    from: userWallet,
    to: config.stakingAddress,
    gas: estimatedGas,
    gasPrice: Web3.utils
      .toBN(gasPrice)
      .mul(Web3.utils.toBN(120))
      .div(Web3.utils.toBN(100))
      .toString(),
    data: dataStake,
  };
  await web3.eth
    .sendTransaction(tx)
    .on("error", (error) => {
      dispatch(setLoading(false));
      toast.error(error.message);
    })
    .then(() => {
      dispatch(setLoading(false));
      window.location.reload();
    });
}

async function claimOnlyRewards(dispatch, userWallet) {
  dispatch(setLoading(true));
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(ABI_STAKING, config.stakingAddress);
  const withdraw = await contract.methods.claimRewards();
  let dataStake = withdraw.encodeABI();
  const gasPrice = await web3.eth.getGasPrice();
  const estimatedGas = await contract.methods
    .claimRewards()
    .estimateGas({ from: userWallet });
  const tx = {
    from: userWallet,
    to: config.stakingAddress,
    gas: estimatedGas,
    gasPrice: Web3.utils
      .toBN(gasPrice)
      .mul(Web3.utils.toBN(120))
      .div(Web3.utils.toBN(100))
      .toString(),
    data: dataStake,
  };
  await web3.eth
    .sendTransaction(tx)
    .on("error", (error) => {
      dispatch(setLoading(false));
      toast.error(error.message);
    })
    .then(() => {
      dispatch(setLoading(false));
      window.location.reload();
    });
}

function disconnectWallet() {
  web3Modal.clearCachedProvider();
}

export {
  connectWallet,
  disconnectWallet,
  requestSignature,
  withdraw,
  getStakeTokenBalance,
  listBrackets,
  getTotalStaked,
  stakeAmount,
  claimStakingRewards,
  checkIfUserIsStaking,
  checkIfCanClaim,
  calculateRewards,
  daysUntilClaim,
  userBracket,
  hasEnoughTokenBalance,
  claimOnlyRewards,
};
